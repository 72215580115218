<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="danger" @click="nuevoCliente">
                            <v-icon>mdi-plus</v-icon>
                           Nuevo Cliente
                        </v-btn>
                     <!--   <div>
                            <v-btn class="ma-2" color="primary">
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn outlined class="ma-2">Import</v-btn>
                            <v-btn outlined class="ma-2">Export</v-btn>
                        </div>-->
                    </div>
                </v-card-title>
                <v-card-title>
                    Clientes
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_clientes"
                    item-key="razon_social"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
  <template v-slot:item.full_dir="{ item }">{{ item.calle }} {{item.numero}} {{item.cruzamientos}} {{item.colonia}} {{ item.municipio }} {{item.estado}}</template>

                    <template v-slot:item.action="{item}">

                        <div class="d-flex">
                             <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="ruta(item.idcliente)"
                                        color="info"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-truck-fast-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Ver Rutas</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="editCliente(item.idcliente)"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="eliminarCliente(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                      <!--  <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>-->
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { url } from "../../main.js";

export default {
    
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Clientes'
    },
    data() {
        return {
            lista_clientes: [],
    
            search: '',
           // selected: [],
            headers: [
                {
                    text: 'Razón Social',
                    align: 'start',
                    sortable: false,
                    value: 'razon_social'
                },
               

                {text: 'RFC', value: 'rfc'},
                {text: 'Teléfono', value: 'telefono'},
                {text: 'Dirección', value: 'full_dir'},
              
                {text: 'Acción', value: 'action'}
            ],
           /* desserts: [
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon Doe',
                    calories: 'Walmart',
                    fat: '95',
                    carbs: '$14,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    name: 'Medusa',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon Doe two',
                    calories: 'Walmart',
                    fat: '95',
                    carbs: '$14,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe two',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend two',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut two',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    name: 'Medusa two',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe three',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend three',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut three',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    name: 'Medusa three',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    name: 'Jhon Doe four',
                    calories: 'Walmart',
                    fat: '95',
                    carbs: '$14,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    name: 'Elizabeth Doe four',
                    calories: 'KFC',
                    fat: '876',
                    carbs: '$38,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    name: 'Jhon Legend four',
                    calories: 'Starbucks',
                    fat: '300',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Deactive'
                },
                {
                    img: require('@/assets/images/avatars/004-bald.svg'),
                    name: 'Juggernaut four',
                    calories: 'Apple',
                    fat: '700',
                    carbs: '$78,384',
                    protein: '03/24/2020',
                    badge: 'Active'
                }
            ]*/
        }
    },mounted(){
 this.permiso = localStorage.getItem("permission");
    this.verClientes();
    },
    
    methods:{
        verClientes() {
      this.axios
        .get(url + "apiClientes.php/clientes")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_clientes = respuesta.data;

          console.log(this.lista_clientes);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
     editCliente(idcliente) {
       this.$router.push("/catalogos/cliente/"+idcliente);

   
    },  ruta(idcliente) {
      this.$router.push("/catalogos/rutas/"+idcliente);

   
    },eliminarCliente(cliente) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar al cliente?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiClientes.php/delete_cliente", cliente)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Cliente Eliminado");
                this.verClientes();
              }
            });
        }
      });
    },
    nuevoCliente()
    {
               this.$router.push("/catalogos/cliente/0");



    }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
